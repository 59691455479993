<template>
  <div class="wrapper">
    <b-button-group size="sm" class="group">
      <b-button
        :variant="active === 'list' ? 'dark' : 'light'"
      >
        <router-link to="/app/bookings/list">
          <fa-icon icon="list" /> List
        </router-link>
      </b-button>
      <b-button
        :variant="active === 'calendar' ? 'dark' : 'light'"
      >
        <router-link to="/app/bookings/timeline">
          <fa-icon icon="calendar" /> Calendar view
        </router-link>
      </b-button>
    </b-button-group>
  </div>
</template>

<style lang="scss" scoped>
  .wrapper {
   text-align: right;
   margin: 0 0 3rem 0;
  }
  .btn a {
    text-decoration: none;
    display: block;
  }
  .btn-dark a {
    color: #fff;
  }
  .btn-light a {
    color: #212529;
  }
</style>

<script>
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faCalendar, faList
} from '@fortawesome/free-solid-svg-icons';

library.add(faCalendar, faList);

export default {
  props: ['active'],
};
</script>
